jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		$(".mobile-navigation-menu").data("mmenu").open();
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767) {
		$(".inside-page-container").fitVids({ ignore: ".nofit" });
		$(".home-section").fitVids({ ignore: ".nofit" });
	}

	// Accessible menu
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .main-nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".dropdown-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	$('.desktop-search-button.search-icon').click(function() {
		$('.hidden-search').css({
			display: 'flex',
			opacity: '1',
		})
		$('.search-input').focus()
	})

	// Webflow
	! function(o, c) {
		var n = c.documentElement,
			t = " w-mod-";
		n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
	}(window, document);

	$(document).ready(function() {
		var $nav = $('.dropdown-wrap');
		$nav.hover(
			function() { // hover in
				$(this).children('.main-nav-link').addClass('hovered');
			},
			function() { // hover out
				$(this).children('.main-nav-link').removeClass('hovered');
			}
		);
	});

	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
	});

	/* eslint-disable */
	Webflow.require('ix').init([
		{ "slug": "close-top-search", "name": "Close Top Search", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".search-container-top", "stepsA": [{ "height": "0px", "transition": "height 500ms ease 0" }], "stepsB": [] }] } },
		{ "slug": "search-top-dropdown", "name": "Search Top Dropdown", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".search-container-top", "stepsA": [{ "height": "auto", "transition": "height 300ms ease 0" }], "stepsB": [{ "height": "0px", "transition": "height 300ms ease 0" }] }] } },
		{ "slug": "dropdown-hover", "name": "Dropdown Hover", "value": { "style": {}, "triggers": [{ "type": "hover", "selector": ".dd-list", "descend": true, "stepsA": [{ "wait": "50ms" }, { "display": "block", "opacity": 1, "height": "auto", "transition": "opacity 300ms ease 0ms, height 300ms ease 0ms" }], "stepsB": [{ "display": "none", "opacity": 0, "height": "0px", "transition": "opacity 300ms ease 0ms, height 300ms ease 0ms" }] }] } },
		{ "slug": "close-large-search", "name": "Close Large Search", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".hidden-search", "stepsA": [{ "display": "flex", "opacity": 0, "transition": "opacity 350ms ease-out-cubic 0" }, { "wait": "350ms", "display": "none" }], "stepsB": [] }, { "type": "click", "selector": ".hidden-search-holder", "preserve3d": true, "stepsA": [{ "transition": "transform 350ms ease-out-quad 0", "x": "0px", "y": "50px", "z": "0px" }], "stepsB": [] }] } },
		{ "slug": "open-large-search", "name": "Open Large Search", "value": { "style": {}, "triggers": [{ "type": "click", "selector": ".hidden-search-holder", "preserve3d": true, "stepsA": [{ "transition": "transform 350ms ease-out-cubic 0", "x": "0px", "y": "-50px", "z": "0px" }], "stepsB": [] }] } },
		{ "slug": "featured-product-lift", "name": "Featured Product Lift", "value": { "style": {}, "triggers": [{ "type": "hover", "selector": ".featured-product-image", "descend": true, "preserve3d": true, "stepsA": [{ "transition": "transform 400ms ease-out-quad 0", "x": "0px", "y": "-10px", "z": "0px" }], "stepsB": [{ "transition": "transform 400ms ease-out-quad 0", "x": "0px", "y": "0px", "z": "0px" }] }] } },
		{ "slug": "parts-icon-lift", "name": "Parts Icon Lift", "value": { "style": {}, "triggers": [{ "type": "hover", "selector": ".parts-icon", "preserve3d": true, "stepsA": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "-5px", "z": "0px" }], "stepsB": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "0px", "z": "0px" }] }] } },
		{ "slug": "top-header-hover", "name": "Top Header Hover", "value": { "style": {}, "triggers": [{ "type": "hover", "stepsA": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "-5px", "z": "0px" }], "stepsB": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "0px", "z": "0px" }] }] } },
		{ "slug": "scroll-into-view-vertical", "name": "Scroll Into View Vertical", "value": { "style": { "opacity": 0, "x": "0px", "y": "50px", "z": "0px" }, "triggers": [{ "type": "scroll", "offsetBot": "20%", "stepsA": [{ "opacity": 1, "transition": "opacity 1000ms ease-out-quad 0, transform 1500ms ease 0", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
		{ "slug": "scroll-into-view-from-left", "name": "Scroll Into View From Left", "value": { "style": { "opacity": 0, "x": "-50px", "y": "0px", "z": "0px" }, "triggers": [{ "type": "scroll", "offsetBot": "20%", "stepsA": [{ "opacity": 1, "transition": "opacity 1000ms ease-out-quad 0, transform 1500ms ease 0", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
		{ "slug": "scroll-into-view-from-right", "name": "Scroll Into View From Right", "value": { "style": { "opacity": 0, "x": "50px", "y": "0px", "z": "0px" }, "triggers": [{ "type": "scroll", "offsetBot": "20%", "stepsA": [{ "opacity": 1, "transition": "opacity 1000ms ease-out-quad 0, transform 1500ms ease 0", "x": "0px", "y": "0px", "z": "0px" }], "stepsB": [] }] } },
		{ "slug": "button-slide-right", "name": "Button Slide Right", "value": { "style": {}, "triggers": [{ "type": "hover", "stepsA": [{ "transition": "transform 450ms ease-out-quad 0", "x": "5px", "y": "0px", "z": "0px" }], "stepsB": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "0px", "z": "0px" }] }] } },
		{ "slug": "button-slide-left", "name": "Button Slide Left", "value": { "style": {}, "triggers": [{ "type": "hover", "stepsA": [{ "transition": "transform 450ms ease-out-quad 0", "x": "-5px", "y": "0px", "z": "0px" }], "stepsB": [{ "transition": "transform 450ms ease-out-quad 0", "x": "0px", "y": "0px", "z": "0px" }] }] } },
		{ "slug": "left-nav-red-diamond", "name": "Left Nav Red Diamond", "value": { "style": {}, "triggers": [{ "type": "hover", "selector": ".left-nav-red-diamond", "descend": true, "stepsA": [{ "opacity": 1, "transition": "opacity 200 ease 0" }], "stepsB": [{ "opacity": 0, "transition": "opacity 200 ease 0" }] }] } }
	]);
	/* eslint-enable */
});
